 .ant-input{
    height: 48px;
}

.selectWithSearch{
    height: 41px;
}

.ant-form-vertical .ant-form-item-label >label {
    color: #1E2022;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    white-space: nowrap;
    z-index: 1;

}

.custom-form .ant-form-item-label >label {
    color: #687980;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    white-space: nowrap;
    z-index: 1;
}


/* profile-card-view */
.full-name{
    width: 200px;
    color: #1E2022;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: -20px;
    margin-left: 115px;
}

.designation-name{
    color: #4079FC;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-left: 115px;
}

.offical-email{
    width: 200px;
    color: #687980;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    white-space: nowrap;
    margin-left: 350px;
}

.mobile-no{
    color: #687980;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    white-space: nowrap;
    position: relative;
    margin-left: 350px;
    margin-top: -20px;
}

.code{
    color: #1E2022;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    position: relative;
    left: 300px;
    top: -70px;
}

.codes{
    color: #687980;
    font-size: 12px;
    position: relative;
    top: -10px;
}

.code-id{
    color: #1E2022;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    position: absolute;
    left: 140px;
    top: -10px;
}

.age{
    color: #687980;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 115px;
    position: relative;
    top: -10px;
}

.age-name{
    color: #1E2022;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    position: relative;
    right: -35px;
    top: -10px;
}

.profile{
    color: #687980;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 115px;
    position: relative;
    top: -20px;
}

.profile-score{
    color: #1E2022;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    position: relative;
    right: -45px;
    top: -20px;
}


:where(.css-dev-only-do-not-override-i0102m).ant-steps.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    white-space: nowrap;
}


.table-card-view{
    border: 1px solid #C9D6DF; 
    border-radius: 5px;
    width: 72px; 
    height: 36px;
    float: right; 
    margin-right: 16px;
    background-color: white;
}

.table-view{
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-top: 4px;
    margin-left: 5px;
}
  
.card-view{
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-left: 15px;
    margin-top: 4px;
}

.active {
    background-color: #4079FC;
    border-radius: 5px;
    width: 25px;
    height: 25px;
  }

.input-width{
    min-width: 200px;
}


.ant-form-item .ant-form-item-label {
    flex-grow: 0;
    overflow: hidden;
    white-space: nowrap;
    vertical-align: middle;
}

.ant-col-sm-8 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 33.33333333333333%;
    max-width: 150.33333333333333%;
}


.ant-breadcrumb li:last-child {
    color: rgba(0, 0, 0, 0.88);
    display: none;
}
.ant-breadcrumb a {
    color: rgba(0, 0, 0, 0.45);
    transition: color 0.2s;
    padding: 0 4px;
    border-radius: 4px;
    height: 22px;
    display: none;
    margin-inline: -4px;
}

.ant-breadcrumb .ant-breadcrumb-separator {
    margin-inline: 8px;
    color: rgba(0, 0, 0, 0.45);
    display: none;
}

.employee-data{
    border-bottom: 1px solid #C9D6DF; 
    height: 64px;
    border-radius: 5px 5px 0px 0px;  
    margin-top: -20px;
}
.employee-data-style{
    font-size: 18px;
    color: #1E2022;
    font-style: normal;
    font-weight: 600px;
    line-height: normal;
    padding-top: 20px;
    font-weight: 600;
    margin-top: 10px;
}

.employee-button{
    background: white;
    border-top: 1px solid #C9D6DF; 
    height: 80px;
    border-radius: 0px 0px 5px 5px;
}
.employee-next-button{
    width: 125px;
    height: 50px; 
    border-radius: 5px; 
    background: #4079FC;
    margin-top: 20px; 
    margin-right: 20px;
    font-size: 16px;
}
.employee-back-button{
    width: 125px;
    height: 50px; 
    border-radius: 5px; 
    background: #FFFFFF;
    margin-top: 20px; 
    float: right; 
    margin-right: 10px;
    font-size: 16px;
}

.employee-backs-button{
    width: 125px;
    height: 50px; 
    border-radius: 5px; 
    background: #FFFFFF;
    margin-top: 20px; 
    float: right; 
    margin-right: 10px;
    font-size: 16px;
    border: 1px solid #4079FC;
}


/* CardView */
.profile_score{
    display: flex; 
    justify-content: center; 
    align-items: center; 
    margin-top: -10px;
}

.cards{
    background: #FFFFFF; 
    cursor: pointer;
    box-shadow: 0px 4px 10px 0px rgba(30, 32, 34, 0.10);
}

.cards:hover{
    cursor: pointer;
    border: none;
}

.card-icon{
    position: absolute;
    right: 10px;
    top:10px; 
    font-size: 20px
}

.card-img{
    border-radius: 60px;
    display: flex;
    margin: 0 auto; 
    margin-top: -5px;
    width: 76px;
    height:76px;
}

.card-employee-name{
    padding-top: 5px; 
    font-size: 16px; 
    text-align: center; 
    margin-left: -12px;
}

.card-employee-department{
    font-size: 14px;
    text-align: center; 
    line-height: 0px; 
    color: #4079FC; 
    white-space: nowrap; 
}

.cards1{
    border: 1px solid #C9D6DF; 
    background: #F9FAFC; 
    height: 184px; 
    border-radius: 5px;
    margin-top:30px; 
}

.card-employee-data{
    color: #687980; 
    padding-left: 10px;
    position: relative;
    top:5px;
}

.card-employee-datas{
    color: #687980; 
    padding-left: 10px;
}


/* EmployeeInfo Style */
.employeeInfo{
    background: white;
    border-bottom: 1px solid #C9D6DF;
    height: 64px;
    border-radius: 50px 50px 0px 0px;
}

.employeeInfo-text{
    font-size: 16px;
    color: #1E2022;
    font-weight: 600;
    font-style: normal;
    line-height: normal;
    padding-top: 20px;
    padding-left: 30px;
}

.employeeInfo-update{
    border-top: 1px solid #C9D6DF;
    height: 70px;
    border-radius: 0px 0px 50px 50px;
}
.employeeInfo-update-save{
    width: 257px;
    height: 44px;
    border-radius: 3px;
    background: #4079FC;
    margin-top: 12px;
    margin-right: 12px;
}

.employeeInfo-update-cancel{
    width: 257px;
    height: 44px;
    border-radius: 5px;
    background: none;
    border: 1px solid #EA4335;
    margin-top: 12px;
    float: right;
    margin-right: 12px;
}

.edit-button{
    width: 72px;
    height: 28px;
    border-radius: 20px;
    background: #4079FC;
    border: 1px solid #4079FC;
    position: absolute;
    top: 20px;
    right: 10px;
    float: right;
    margin-right: 15px;
}

.edit-button-text{
    color: #FFFFFF;
    font-size: 14px;
    font-size: normal;
    font-weight: 700;
    line-height: normal;
    position: relative;
    right: 3px;
    bottom: 2px;
}



.edit-button-CNIC{
    width: 72px;
    height: 28px;
    border-radius: 20px;
    background: #4079FC;
    border: 1px solid #4079FC;
    position: relative;
    bottom: 267px;
    right: 10px;
    float: right;
    margin-right: 15px;
}

.edit-button-CNIC-text{
    color: #FFFFFF;
    font-size: 14px;
    font-size: normal;
    font-weight: 700;
    line-height: normal;
    position: relative;
    right: 3px;
    bottom: 2px;
}


/* Steps Style */

.step1{
    width: 250px;
}

@media(max-width: 1160px){
    .step1{
      width: 230px;
    }
}

@media(max-width: 1070px){
    .step1{
      width: 210px;
    }
}

@media(max-width: 990px){
    .step1{
      width: 190px;
    }
}

@media(max-width: 910px){
    .step1{
      width: 170px;
    }
}

@media(max-width: 830px){
    .step1{
      width: 150px;
    }
}

@media(max-width: 750px){
    .step1{
      width: 130px;
    }
}

@media(max-width: 670px){
    .step1{
      width: 110px;
    }
}

@media(max-width: 590px){
    .step1{
      width: 107px;
    }
}

@media(max-width: 575px){
    .step1{
      width: 250px;
    }
}

@media(max-width: 575px){
    .step1{
      width: 220px;
    }
}


@media(max-width: 910px){
    .search-employees{
        min-width: 100px;
        margin-top: 50px;
    }
}

.search-employees{
    min-width: 150px; 
    border-radius: 5px; 
    background: #FFFFFF;
}

@media(max-width: 1290px){
    .card-employee-name{
        font-size: 14px;
    }
}

@media(max-width: 1050px){
    .card-employee-name{
        font-size: 14px;
    }
}

@media(max-width: 650px){
    .card-employee-name{
        font-size: 14px;
    }
}

@media(max-width: 575px){
    .card-employee-name{
        font-size: 16px;
    }
}

@media(max-width: 400px){
    .card-employee-name{
        white-space: wrap;
    }
}

.total-employee{
    position:absolute; 
    left:0; 
    font-weight: 600; 
    margin-top: -15px;
    font-family: 'Switzer-Bold';
}

@media(max-width: 525px){
    .total-employee{
        margin-top: -50px;
    }
}

@media(max-width: 390px){
    .cards{
        width: 250px;
    }
}
