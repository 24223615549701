


.card-container{
  margin-bottom: 5px;
}
.card-col{
  height: auto;
  overflow: auto;
  margin: 0px !important;
  padding: 0;
}
.single-card{
  margin-bottom: 3px;
  margin-left: 0px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;

}
.single-card-container {
  padding: 0px !important;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  background-color:#F8F8F8 ;
}
.single-card-container .ant-card-body {
  padding: 0px !important;
 
}
.single-card-container .ant-card-head {
  padding: 10px !important;
}
.single-card .ant-card-head {
  padding: 0px !important;

}