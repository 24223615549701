
.clear-fix{
  display: block;
  clear: both;

}
  .flow-table{
position:relative;
border: 1px solid black;
text-align: center;
margin-top: 5%;
overflow-x: scroll;
  }
  .flow-table td{
border: 1px solid black;
padding: 0;
padding: 2px;
width: 100;
  }
  .flow-table th{
border: 1px solid black;
padding: 0;
  padding: 2px;
  width: 100;
  }
  .flow-table td .form-item{
    padding: 5px;
  }

