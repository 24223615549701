.attendance-summary{
  color: #1E2022;
  font-family: 'Switzer-Semibold';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}


.ng-scope{
  margin: auto;
}

.badge.badge-single {
  margin: 12px;
  padding: 0; 
  text-align: center;
  width: 16px;
  height: 16px;
  line-height: 16px;
  border-radius: 40px;
}

.bg-error.dker {
  background-color: #a02622 !important;
}

.bg-orange {
  background-color: #ffc100 !important;
  color: white !important;
}

.bg-day-off {
  background: #36c2aa;
  color: #fff;
}
.bg-default-time-slot {
  background-color: #5368a2;
}

.bg-tr-black {
background-color: rgba(0, 0, 0, 0.3) !important;
  color: #616f77 !important;
}

.bg-informed-late {
  color: white !important;
  background-color: #FF5722;
}

.bg-darkgray {
  background-color: #333 !important;
  color: white !important;
}

.bg-greensea.dker {
  background-color: #107863 !important;
}

.bg-info {
  background-color: #f5791a !important;
  color: white !important;
}

.bg-f5 {
  background: #E5E5E5;
    color: #616f77 !important;
}

