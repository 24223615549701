.heading {
  
}
.heading h1 {
  color: #1E2022;
    font-family: Switzer;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    font-family: 'Switzer-Regular' !important;
}

.widget {
 text-align: right;
}

.widgetbox {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Switzer-Regular' !important;
  margin-bottom: 30px;
  padding-bottom: 20px;
  overflow: hidden;
}

.widgetbox h3 {
  color: #1E2022;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 20px;
  padding-left: 20px;
}
.widgetboxminH {
  min-height: 450px;
}

.widgetboxfixH {
  max-height: 400px;
  overflow-y: scroll;
}

.widgetboxfixH::-webkit-scrollbar {
  width: 5px;
}

.widgetboxfixH::-webkit-scrollbar-track {
  background: none; 
}

.widgetboxfixH::-webkit-scrollbar-thumb {
  background: #cecece; 
}

.widgetcontent {
  margin-top: 20px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  border-top: 1px solid #C9D6DF;
  
}
.dashboardcol {
  margin-bottom: 30px;
}

.widgetbtn{
  width: 153px;
  height: 44px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #4079FC;
  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-family: 'Switzer-Regular' !important;
}

.dashboardbox {
  margin-top: 20px;

}

/* Birthdays css Start*/

.birthdaybox {
  width: 353px;
  height: 470px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Switzer-Regular' !important;
}

.birthdaybox h3{
  color: #1E2022;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 20px;
  padding-left: 20px;
  margin-bottom: 16px;
}

.birthdaybox span{
  padding: 10px;
}

.birthdaycontent {
  border-top: 1px solid #C9D6DF;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  height: 450px;
  overflow-y: scroll;
}

.birthdaymain {
  height: 60px;
  margin-top: 20px;
}

.birthdayimg {
  width: 45px !important;
  height: 45px !important;
  border-radius: 45px !important;
  float: left;
  margin-right: 10px;
}

.birthdayimg img {
  width: 45px !important;
  height: 45px !important;
  border-radius: 45px !important;
}

.birthdaydetails {
  margin-left: 5px;
  margin-top: 5px;
  height: 60px;
}

.birthdayname {
  color: #1E2022;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-family: 'Switzer-Regular' !important;
}

.birthdaycode {
  color: #4079FC;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-family: 'Switzer-Regular' !important;
}

.birthdaydate {
  color: #687980;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 10px;
  margin-right: 20px;
}

.birthdaysingle{
  border-bottom: 1px solid #C9D6DF;
  margin-top: 20px;
}

.birthdaycontent::-webkit-scrollbar {
  width: 0px;
}

.birthdaycontent::-webkit-scrollbar-track {
  background: none; 
}

.birthdaycontent::-webkit-scrollbar-thumb {
  background: #cecece; 
}

/* Birthdays css End*/

/* news letter css start */
.newsLetterContent {
  border-top: 1px solid #C9D6DF;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  height: 300px;
  overflow-y: auto;
}
.newsTitle {
  color: #1E2022;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  font-family: 'Switzer-Regular' !important;
}
/* news letter css end */

/* Gender Count css Start*/


.apexcharts-legend-text{
  font-family: 'Switzer-Regular' !important;
  margin-left: -10px !important;
}


.genderbox {
  width: 353px;
  height: 470px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Switzer-Regular' !important;
}

.genderbox h3 {
  color: #1E2022;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 20px;
  padding-left: 20px;
}

.gendermain {
  margin-top: 20px;
  border-top: 1px solid #C9D6DF;
}

.genderdetails {
  margin-left: 20px;
  margin-top: 20px;
  text-align: center;
}

.gendercount {
  color: #1E2022;
  font-family: 'Switzer-Regular' !important;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.gendertitle {
  color: #687980;
  font-family: 'Switzer-Regular' !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.gendertotal {
  color: #1E2022;
  text-align: right;
  font-family: 'Switzer-Regular' !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.gendertotalcount {
  text-align: left;
  margin-right: 20px;
  margin-top: 30px;
}

.gendertotalcount .ant-col{
  margin-bottom: 10px;
}

/* Gender css End */

.changeDate {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  float: right;
}

.changeDate .ant-picker {
  width: 95%;
}

/* Department summary css start */

.summarysinglecol {
  width: 100%;
  border-left: 3px solid #449D44;
  background-color: #fff !important;
  box-shadow: 0 2px 8px -2px #ccc;
  padding: 5px;
  font-weight: bold;
  padding-right: 10px;
}
.headcount {
  text-align: right;
  padding-right: 20px;
  font-size: 16px;
  color: #5688FB;
}

.thead-dark {
  background-color: #4079FC !important;
  color: #fff !important;

}

.hiddenRow {
  padding: 0 !important;
}
.ant-collapse-expand-icon {
  width: 50px !important;
}
.ant-collapse .ant-collapse-header {
  border-radius: 5px !important;
  background-color: #fff;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
  display: block;
}

:where(.css-dev-only-do-not-override-2q8sxy).ant-collapse>.ant-collapse-item>.ant-collapse-header {
  padding: 0px 0px 0px 0px !important;
}

.ant-collapse-expand-icon {
  display: none !important;
}

:where(.css-dev-only-do-not-override-2q8sxy).ant-collapse .ant-collapse-content>.ant-collapse-content-box {
  padding: 0px 0px;
  margin-left: 20px;
}

:where(.css-dev-only-do-not-override-2q8sxy).ant-collapse {
  border: none !important;
}

:where(.css-dev-only-do-not-override-2q8sxy).ant-collapse>.ant-collapse-item {
  border: none !important;
}

:where(.css-dev-only-do-not-override-2q8sxy).ant-collapse .ant-collapse-content {
  border: none !important;
}

.table {
  margin-bottom: 0px !important;
}

.markclockin {
  border-radius: 5px;
  border: 1px solid #C9D6DF;
  background: #F9FAFC;
  margin-right: 20px;
  padding-left: 20px;
  padding-top: 10px;
}

.markclockin h4{
color: #687980;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.markclockin p{
  color: #1E2022;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.markclockout h4{
color: #687980;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.markclockout p{
  color: #1E2022;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.markclockout {
  border-radius: 5px;
  border: 1px solid #C9D6DF;
  background: #F9FAFC;
  margin-left: 20px;
  margin-right: 20px;
  padding-left: 20px;
  padding-top: 10px;
}

.markclockinout {
  margin-top: 20px;
  text-align: center;
}

.clockTime {
  text-align: center;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 10px;
}

.checkinoutBtn {
  margin-top: 30px;
}

.checkoutbtn {
  border-radius: 5px;
  background: #4079FC;
  color: #FFF;
  width: 95%;
  height: 50px;
  font-size: 16px;
}

.dayoff {
  border-radius: 5px;
  color: #EA4335;
  background: #FDECEA;
  width: 95%;
  height: 50px;
  font-size: 20px;
  text-align: center;
  padding-top: 10px;
}


.breakBtn {
  border-radius: 5px;
  background: rgba(64, 121, 252, 0.10);
  color: #4079FC;
  width: 95%;
  height: 50px;
  font-size: 16px;
}

.attendancSbox {
  width: 121px;
  height: 120px;
  background: #EA4335;
  padding: 20px;
  margin: auto;
  border-radius: 5px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
}

.attendancSbox h4 {
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  height: 40px;
}
.attendancSbox h3 {
  color: #FFF;
  font-size: 32px;
  text-align: left;
  padding: 0px;
  margin: 0px;
}

.leaveSummaryBox {
  width: 100%;
  height: 300px;
  background: #f1f0f0;
  border-radius: 5px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 2px;
  margin-top: 2px;
  padding-right: 0px;
}

.leaveSummaryBox h5{
  text-align: center;
  padding-top: 20px;
}

.leaveRemain {
  padding-left: 50px;

}

.leaveinfobox {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  font-size: 20px;
  text-align: center;
  padding-top: 15px;
  margin: auto;
  margin-bottom: 50px;
}

.leaveSummaryBoxp{
  font-weight: bold;
  color: #4079FC !important;
  
}
