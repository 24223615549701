   /* @import url(https://fonts.googleapis.com/earlyaccess/notonaskharabic.css); */

   /* p {
      font-family: 'Noto Naskh Arabic', serif;
      font-size: 1.5em;
      direction: rtl;
    } */

   /* body {
      font-family: ;
    } */

   #payslip {
     border: 1px solid;
     margin: 15px 15px 10px;
   }

   #payslip td:last-child,
   #payslip th:last-child {
     border-right: 1px solid;
   }

   #payslip td:first-child,
   #payslip th:first-child {
     border-left: 1px solid;
   }


   #payslip td {
     border-bottom: none;
     border-top: none;

   }



   #payslip th {
     border-bottom: none;
     border-top: none;

   }

   #payslip .column-full {
     padding: 10px 15px;
   }

   #payslip .column-left {
     float: left;
     width: 50%;
     padding: 10px 15px;
     color: #666;
   }

   #payslip .column-right {
     width: 50%;
     float: right;
     padding: 10px 15px;
   }

   #payslip table {
     width: 100%;
   }

   #payslip .payslip-info-outer li,
   /*#payslip .column-left td,
*/
   #payslip .column-right td {
     color: #666;
   }

   #payslip .custom-font {
     padding: 15px 15px;
     margin: 0;
     font-size: 20px;
     color: #666;
     border-bottom: 1px solid #e6e6e6;
   }

   .tile-body {
     padding: 10px 0;
   }

   .text-right {
     text-align: center !important;
     color: #666;
   }

   .bg-blue tr th {
     background-color: rgb(250, 67, 34) !important;
     background: rgb(250, 67, 34) !important;
     color: #ffff !important;
     padding: 5px !important;
   }

   .bg-blue tr th,
   tbody tr td {
     padding: 5px !important;
   }

   #payslip th {
     text-align: left;
     /* border: 1px solid ;
    border-right: none;
    border-left: none;*/
   }

   .bottom-table>table {
     border-bottom: 1px solid black;
   }