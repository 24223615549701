.actionButton {
  width: 100%;
  height: 56px;
  background: #4079FC;
  border-radius: 8px;
  color: white;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
}    

.ant-input-affix-wrapper >input.ant-input {
  padding: 0;
  font-size: inherit;
  border: none;
  border-radius: 0;
  outline: none;
  background: none;
}

.background-color{
  background: #F3F6F9;
}


.klok-forgot {
  color: #1E2022;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.klok-forgot-text {
  color: #687980;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  width: 329px;
  margin-left: 67px;
}

.anticon-close {
  color: red;
}



.authRightPanel {
  background: linear-gradient(155deg, #2F57FF 6.16%, #00197C 92.32%);
  height:930px;
  width: 850px;
  z-index: 1;
}

.klok-logo{
    width:60px !important;
    height: 57px !important;
    margin: auto;
    text-align: center;
    position: absolute;
    top: 35px;
    left: 60px;
}

.title {
  color: #FFFFFF;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
   position: relative;
  top: 110px;
}

.carousel-text{
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  position: absolute;
  top: 160px;
}

.de{
  width: 798px;
  height: 600px;
  background: rgba(130, 154, 255, 0.50);
  position: absolute;
  bottom:-0px; 
  left: 52px;
  border-top-left-radius: 10px;
}

.login-dashboard{
  width: 783px;
  height: 585px;
  margin-left: 15px;
  margin-top: 15px;
}




.klok-form-background{
  border-radius: 10px;
  background: #FFFFFF;
  position:absolute;
  top: 160px;
  right: 50px;
  left: 225px;
}

.klok-input{
  margin-left: 30px;
  margin-right: 30px;
}

.form-logo{
  margin-left: 80px;
  padding-top: 30px;
}

.klok-form-title{
  color: #1f221e;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 20px;
}

.klok-form-text{
  color: #687980;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}







.ant-input-affix-wrapper {
  position: relative;
  display: inline-flex;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  font-size: 14px;
  line-height: 1.5714285714285714;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #C9D6DF;
  border-radius: 6px;
  transition: all 0.2s;
  background: rgba(201, 214, 223, 0.10);
  height: 56px;
}

/* Form input error background color and border color */
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
  border-color: #EA4335;
  background: rgba(234, 67, 53, 0.10);
}

/* Form input error icon color */
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper .ant-input-prefix {  
  color: #EA4335;
}

 /* error massega color */
.ant-form-item .ant-form-item-explain-error {
  color: #EA4335;
}


/* form label */
.ant-form-vertical .ant-form-item-label >label{
  color: #1E2022;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}


/* .ant-form-item-label label::before {
  display: none !important;
} */



.lock-icon.focused {
  color: #4079FC;
}

.lock-icon.error {
  color: #EA4335;
}



.mail-icon.focused {
  color: #4079FC;
}

.mail-icon.error {
  color: #EA4335;
}

.groups{
  margin-left: 670px;
  margin-top: 130px;
}

.groups2{
  margin-left: 10px;
  margin-top: 270px;
}

/* @media(max-width: 1150px){
  .authRightPanel{
    width: 1250px;
  }
  .klok-form-background{
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    left: -280px;
  }
  .groups{
    margin-left: 620px;
  }
  .de{
    width: 749px;
  }
  .login-dashboard{
    width: 734px;
  }
  .form-logo{
    margin-left: 50px;
  }
  
} */


@media(max-width: 1265px){
  .authRightPanel{
    width: 800px;
  }
  .groups{
    margin-left: 620px;
  }
  .de{
    width: 749px;
  }
  .login-dashboard{
    width: 734px;
  }
  .form-logo{
    margin-left: 50px;
  }
  
}


@media(max-width: 1185px){
  .authRightPanel{
    width: 750px;
  }
  .groups{
    margin-left: 570px;
  }
  .de{
    width: 698px;
  }
  .login-dashboard{
    width: 684px;
  }
  .klok-form-title{
    font-size: 20px;
  }
}


@media(max-width: 1115px){
  .authRightPanel{
    width: 710px;
  }
  .groups{
    margin-left: 530px;
  }
  .de{
    width: 658px;
  }
  .login-dashboard{
    width: 644px;
  }
  .title{
    font-size: 35px;
  }
}


@media(min-width: 1410px){
  .authRightPanel{
    width: 880px;
  }
  .groups{
    margin-left: 700px;
  }
  .de{
    width: 828px;
  }
  .login-dashboard{
    width: 814px;
  }
  .title{
    font-size: 40px;
  }
  .form-logo{
    margin-left: 95px;
  }
}


@media(min-width: 1480px){
  .authRightPanel{
    width: 930px;
  }
  .groups{
    margin-left: 750px;
  }
  .de{
    width: 878px;
  }
  .login-dashboard{
    width: 864px;
  }
  .title{
    font-size: 40px;
  }
  .form-logo{
    margin-left: 100px;
  }
}


@media(min-width: 1580px){
  .authRightPanel{
    width: 980px;
  }
  .groups{
    margin-left: 800px;
  }
  .de{
    width: 928px;
  }
  .login-dashboard{
    width: 914px;
  }
  .title{
    font-size: 40px;
  }
  .form-logo{
    margin-left: 130px;
  }
}


@media(min-width: 1680px){
  .authRightPanel{
    width: 1020px;
  }
  .groups{
    margin-left: 840px;
  }
  .de{
    width: 968px;
  }
  .login-dashboard{
    width: 954px;
  }
  .title{
    font-size: 40px;
  }
  .form-logo{
    margin-left: 160px;
  }
}


/* @media(min-width: 1680px){
  .authRightPanel{
    width: 1070px;
  }
  .groups{
    margin-left: 890px;
  }
  .de{
    width: 1019px;
  }
  .login-dashboard{
    width: 1004px;
  }
  .title{
    font-size: 40px;
  }
  .form-logo{
    margin-left: 175px;
  }
} */


/* @media(min-width: 1680px){
  .authRightPanel{
    width: 1120px;
  }
  .groups{
    margin-left: 940px;
  }
  .de{
    width: 1069px;
  }
  .login-dashboard{
    width: 1055px;
  }
  .title{
    font-size: 40px;
  }
  .form-logo{
    margin-left: 205px;
  }
} */