.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
    color: #14AE5C;
}


.ant-steps .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
    color: rgba(0, 0, 0, 0.88);
}


.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #14AE5C;
    border-color:#14AE5C;
    fill: #FFFFFF;
}


.ant-steps .ant-steps-item-finish .ant-steps-item-icon >.ant-steps-icon {
    color: #FFFFFF;
}


.ant-steps .ant-steps-item-title {
    position: relative;
    display: inline-block;
    padding-inline-end: 480px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 16px;
    line-height: 32px;
}


.ant-form-item .ant-form-item-label {
    flex-grow: 0;
    white-space: nowrap;
    vertical-align: middle;
}


.ant-col-2 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 8.333333333333332%;
    max-width: 1500.333333333333332%;
}


.ant-col-6 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 50%;
    max-width: 100%;
}


.button-style{
    border-top: 1px solid #C9D6DF; 
    height: 80px;
}

.button-next{
    width: 125px; 
    height: 50px;
    border-radius: 5px; 
    background: #4079FC;
    font-size:16px;
    float: right;
    font-weight: 600px;
    margin-top:20px; 
    margin-right: 20px;
    position: sticky;
}

.button-back{
    width: 125px; 
    height: 50px;
    border-radius: 5px; 
    background: #FFFFFF;
    border: 1px solid #4079FC;
    margin-top:20px;  
    margin-left:70px;
    font-size:16px;
    font-weight: 600px;
}



/* searchEmployees style */
.button-style-filter{
    background: white;
    border-top: 1px solid #C9D6DF; 
    height: 80px;
    margin-left:-20px;
    margin-top: 20px;
    border-radius: 0px 0px 50px 50px;
}

.button-applyFilter{
    float: right; 
    margin: 5px;
    width: 140px; 
    height: 42px; 
    border-radius: 5px; 
    background: #4079FC;
    margin-top:20px; 
    margin-right: 20px;
    font-size:16px;
    font-weight: 600px;
}

.button-reset{
    float: right; 
    margin: 5px;
    width: 100px; 
    height: 42px; 
    border-radius: 5px; 
    background:rgba(234, 67, 53, 0.10);
    margin-top:20px; 
    margin-right: 20px;
    font-size:16px;
    font-weight: 600px;
    border:1px solid #EA4335;
}

.unselected-employees-style{
    height:465px;
    overflow: auto;
    border:1px solid #C9D6DF;
    border-radius:10px;
    /* margin-left:-20px; */
    margin-top:30px;
    background: rgba(201, 214, 223, 0.10);
}

.unselected-employees-style1{
    height:465px;
    overflow: auto;
    border:1px solid #C9D6DF;
    border-radius:10px;
    margin-top:30px;
    background: rgba(201, 214, 223, 0.10);
    /* margin-left:-20px; */
}


.unselected-employees{
    border-bottom: 1px solid #C9D6DF; 
    height: 64px;
    border-radius: 5px 5px 0px 0px;
}

.unselected-employees-text{
    font-size: 18px;
    color: #1E2022;
    font-size: normal;
    font-weight: 600;
    line-height: normal;
    padding-top: 20px;
    padding-left: 20px;
}

.unselected-employees-button{
    /* width: 46px; */
    height: 46px;
    color: gray;
    border-radius: 5px;
    margin-left: 500px;
    margin-top: -55px;
    border: 1.9px solid #C9D6DF;
    background: rgba(201, 214, 223, 0.10);
    border-radius:10px;
    cursor: pointer;
    margin-right: 20px;
    float: right;
}

.unselected-employees-button1{   
    height: 46px;
    color: gray;
    border-radius: 5px;
    /* margin-left: 480px; */
    margin-top: -55px;
    border: 1.9px solid #C9D6DF;
    background: rgba(201, 214, 223, 0.10);
    border-radius:10px;
    cursor: pointer;
    margin-right: 20px;
    float: right;
}




/* Shift Edit */
.roaster-edit{
    width:1200px;
    height:58px;
    margin-left:-44px;
    border-bottom: 1px solid #C9D6DF;
    background: #FFFFFF;
}

.roaster-edit-text{
    font-size: 24px;
    color: #1E2022;
    font-style: normal;
    font-weight: 700px;
    line-height: normal;
    margin-top:-30px;
    margin-left:-20px;
}


/* CompanyRoster */
.employee-full-name{
    margin-bottom: 0px;
    /* position:relative; */
    /* left:5px; */
    /* top:-10px; */
    /* margin-bottom: 3px; */
    /* margin-top: 5px; */
}

/* .employee-code{ */
    /* position:relative;  */
    /* left:5px; */
    /* margin-bottom: 0px; */
    /* top:-25px; */
    /* white-space: nowrap; */
/* } */


.each-employment {
    width: 70%;
    margin-left: 20px;
}

.each-employee{
    position:relative;  
    top: -35px;
    padding-left: 59px;
    padding-bottom: 50px;
}

.each-designation-name{
    color: #687980;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}    

.each-full-name{
    color: #1E2022;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 3px;
}    

.each-full-name-{
    position:relative;
    left: 11px;
    color: #1E2022;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}    

.each-profile-pic-thumb-url{
    width: 34px;  height: 34px; 
    border-radius: 34px;
    margin-top:15px;
    margin-left:15px;
}






.roster-time-button{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95px;
    height: 32px;
    margin: auto;
    border: none;
    margin-left: 20px;
    padding-left: 33px;
    background-size: 14px;
    background-repeat: no-repeat;
    background-position: 15px center;
    margin-bottom:10px;
    margin-top: 18px;
}

.roster-dayoff{
    background: rgba(234, 67, 53, 0.10);
    color: #EA4335;
    border-radius: 5px;
    border: none;
    padding-left: 32px;
    background-size: 14px;
    background-repeat: no-repeat;
    background-position: 15px center;
}

.Employee-Name{
    margin-right: 2px;
    color: #4079FC; 
    font-size: 16; 
    font-weight: 600; 
    margin-bottom: -3px; 
    height: 24px; 
    margin-left: 70px;
}

.moveEmployee{
    width: 200px;
    height: 64px; 
    border: 1px solid #C9D6DF; 
    border-radius: 5px; 
    margin-top: 13px; 
    margin-left: 16px;
    
}
.moveEmployee:hover{
    cursor: pointer;
    border: 1px solid #4079FC;
/* background: rgba(64, 121, 252, 0.05); */
}

.steps-style{
    height: 60px;
    border: 1px solid #C9D6DF; 
    background: rgba(64, 121, 252, 0.05); 
    margin-left: -10px;
}

@media(max-width: 575px){
    .steps-style{
       height: 200px;
       border: none;
       background: none;
    }
}

.steps-step1{
    padding-top: 12px; 
    white-space: nowrap; 
   
}

.steps-step2{
    padding-top: 12px; 
    border-right: none; 
    white-space: nowrap; 
}

.steps-step3{
    padding-right: 200px;
    padding-top: 12px; 
    border-right: none; 
    white-space: nowrap; 
}

.steps-step4{
    padding-top: 12px; 
    white-space: nowrap;  
}

.steps-step5{
    padding-right: 350px; 
    padding-top: 12px; 
    border-right: none; 
    white-space: nowrap; 
}

@media(max-width: 1100px){
    .steps-step5{
       padding-right: 250px;
    }
}

@media(max-width: 800px){
    .steps-step5{
       padding-right: 100px;
    }
}

@media(max-width: 400px){
    .steps-step5{
       padding-right: 0px; 
    }
}

@media(max-width: 408px){
    .button-style-filter{
        height: 150px;
    }
    .button-reset{
        text-align: center;
        width: 85%;
    }
    .button-applyFilter{
        width: 85%;
    }
}

@media(max-width: 950px){
    .steps-step3{
       padding-right: 100px; 
    }   
}

@media(max-width: 800px){
    .steps-step3{
        padding-right: 50px; 
     }
}

@media(max-width: 620px){
    .steps-step3{
        padding-right: 15px; 
     }
}

@media(max-width: 575px){
     .steps-step1{
        margin-left: -50px;
     }
     .steps-step4{
        margin-left: -100px;
     }
}

.multiselect-button{
    position: absolute; 
    top: 60px; 
    right: 5px;
}

.download-buttons{
    width: 36px;
    height: 36px;
    border-radius: 5px 4px 4px 5px;
    border: 1px solid #C9D6DF;
    background: #F9FAFC;
    margin-left: -10px;
    font-size: 23px;
    font-weight: 700px;
    position: absolute; 
    top: 60px;
    right: 273px;
}

@media(max-width: 648px){
    .download-buttons{
        margin-top: 40px;
    }
    .multiselect-button{
        margin-top: 40px;
    }
}




.preview-employee-table{
    border: 1px solid #C9D6DF;
    border-radius: 10px;
    height: 465px;
    overflow: auto;
    background: rgba(201, 214, 223, 0.10);
}

.preview-employee-title{
    border-bottom: 1px solid #C9D6DF;
    height: 64px;
    border-radius: 5px 5px 0px 0px;
}

.preview-employee-title-style{
    font-size: 18px;
    color: #1E2022;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-top: 20px;
    padding-left: 20px;
}

