.orgchart-container {
  border: none;
  padding-top: 50px;
  border: 1px solid #C9D6DF !important;
  border-radius: 10px;
}


.orgchart {
  background-image: none;
  padding: 100px;
  padding-top: 0px;
  
}

.oc-node {
  margin: 50px !important;
  margin-right: 15px !important;
  margin-left: 15px !important;
}

.orgchatbox {
  width: 246px;
  flex-shrink: 0;
  padding-bottom: 20px;
  border-top: 4px solid #4079FC;
  background: #FFF;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  border-left: 1px solid #C9D6DF !important;
  border-right: 1px solid #C9D6DF !important;
  border-bottom: 1px solid #C9D6DF !important;
}

.orgchart ul li .oc-node:not(:only-child)::after {
  content: "";
  position: absolute;
  bottom: -40px;
  left: calc(50% - 1px);
  width: 2px;
  height: 40px;
  background-color: rgba(185, 185, 185, 0.8);
}

.orgchart>ul>li>ul li>.oc-node::before {
  content: "";
  position: absolute;
  top: -62px;
  left: calc(50% - 1px);
  width: 2px;
  height: 34px;
  background-color: rgba(185, 185, 185, 0.8);
}

.orgchart>ul>li>ul li::before {
border-top: 2px solid rgba(185, 185, 185, 0.8);
}

.orgimage{
  width: 76px;
  height: 76px;
  border-radius: 50%;
  border: 5px solid #ffffff;
  position: absolute;
  top: -38px;
  left: 35%;
  overflow: hidden;
}
.orgimage img{
  width: 70px;
  height: 70px;
}

.orgname{
  margin-top: 45px;
  color: #1E2022;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.orgcode {
  color: #1E2022;
  text-align: right;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  float: right;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.orginfo{
  border-radius: 5px;
  border: 1px solid #C9D6DF;
  background: #F9FAFC;
  color: #687980;
  margin: 0 auto;
  font-family: 'Roboto', sans-serif;
  font-size: 13px !important;
  margin-top: 20px;
  text-align: left;
  margin-left: 10px;
  margin-right: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 5px;
  padding-bottom: 5px;
 
}

.orgkey {
  float: left;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.clearboth {
  clear: both;
}