.permissions-container{
  align-items: center;
  align-content: center;
  width: 100vh;
 
}
.permissions-table{
border: 1px solid black;
color: green;
width: 100vh;
width: 100%;
overflow-x: scroll;  
}
.update-form{
  width: 100vh;
  width: 100%;
}
.permissions-table th {
  border: 1px solid black;
}
.permissions-table td{
border: 1px solid black;
}
ol.actions li {
  display: inline;
  float: left;
  
}

.permissions-continer{
  border: 1px solid black;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: inherit;
}
.actions-continer{
  border: 1px solid blue;
  
  flex-direction: row;
}
.modules-checkbox{
  margin-right: 80%;
}


