.role-permissions-wrapper .panel-heading {
  background: #fafafa;
  position: relative;
}

.role-permissions-wrapper .panel-heading:hover h4 {
  left: 5px;
}

.role-permissions-wrapper .panel-heading:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  background: #666;
}

.role-permissions-wrapper .panel-heading h4 {
  position: relative;
  transition: all 0.1s ease;
  cursor: pointer;
  left: 0;
  width: calc(100% - 30px);
  width: -webkit-calc(100% - 30px);
}

.role-permissions-wrapper .panel-collapse .panel-body {
  padding: 15px;
}

.fieldset-wrapper-tab .role-permissions-wrapper .panel-title:before {
  width: 0;
}

.hide-icon {
  display: none;
}

.show-icon {
  display: inline-block;
    color: blue;
    font-size: large;
}

.show-icon:hover{
  cursor: pointer;
}
