.ant-btn-primary {
    color: #fff;
    background-color: #4079FC;
    box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}

.delete-button{
    height: 36px;
    background-color: #FFFFFF;
    border-radius: 3px;
    color: #EA4335;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: 1px solid #EA4335;
}

.download-button{
    height: 36px;
    border-radius: 5px;
    background-color: #4079FC;
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.download-button-icon {
    font-size: 14px;
    font-weight: 700px;
}

.refresh-button{
    width: 36px;
    height: 36px;
    border-radius: 5px 4px 4px 5px;
    border: 1px solid #C9D6DF;
    background: #F9FAFC;
}
.refresh-button-icon {
    margin-left: -5px;
}

.filters-button{
    height: 36px;
    border-radius: 5px;
    background-color: #4079FC;
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.import-button{
    height: 36px;
    border-radius: 3px;
    border: 1px solid #4079FC;
    background: #F9FAFC;
    color: #4079FC;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.import-button-icon{
    color: #4079FC;
    font-size: 17px;
    font-weight: 700px;
}

.savebtn {
    width: auto;
    height: 40px;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-family: 'Switzer-semibold' !important;
}

.cancelBtn {
    width: auto;
    height: 40px;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-family: 'Switzer-semibold' !important;
}



.create-button{
    height: 36px;
background-color: #4079FC;
    border-radius: 5px;
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.custom-button{
    background-color: #4079FC;
    color: #FFF;
        border-radius: 5px;
        height: 36px;
        border-radius: 5px;
        font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.custom-button:hover{
    color: #C9D6DF !important;
}

.tableview-icon{
    margin-left: -5px;
}

.cardview-icon{
    margin-left: -5px;
}

.multiselect-button{
    width: 160px;
    height: 36px;
    border-radius: 3px;
    border: 1px solid #4079FC;
    background: #F9FAFC;
    color: #4079FC;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.multiselect-button-icon{
    color: #4079FC;
    width: 16px;
    height: 16px;
}

.next-button{
   width: auto;
    height: 36px;
    border-radius: 3px;
    border: 1px solid #4079FC;
    background: #4079FC;
    color: #FFFFFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.next-button-icon{
    font-weight: 600;
    font-size: 13px;
}

.previous-button{
    width: auto;
    height: 36px;
    border-radius: 3px;
    border: 1px solid #4079FC;
    background: #F9FAFC;
    color: #4079FC;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.previous-button-icon{
    width: 22px;
    height: 22px;
    font-weight: 600;
    font-size: 13px;
}


.create-roster{
    height: 36px;
    background-color: #4079FC;
    border-radius: 3px;
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}


.save-button-style{
    border-top: 1px solid #C9D6DF;
    margin-top:20px;
    width:1100px;
    margin-left:-24px;
}

.button-save{
    width: 125px; 
    height: 50px;
    border-radius: 5px; 
    background: #4079FC;
    font-size:16px;
    font-weight: 600px;
    margin-top:35px; 
}

.button-cancel{
    width: 125px; 
    height: 50px;
    border-radius: 5px; 
    font-size:16px;
    font-weight: 600px;
    margin-right: 40px;
}


.add-but-icon{
    margin-left: -7px;
    /* margin-bottom: 10px; */
    color: white;
}
.float-left{
    width: 3px;
    background-color: #4079FC;
    margin-top: 8px;
}
