.logo-style{
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #C9D6DF;
    background: rgba(201, 214, 223, 0.10);
}
.anticon-paper-clip{
    font-size: 14px;
    position: absolute;
    left: 8px;
    top: -15px;
}


.ant-checkbox-inner{
  padding: 10px;
}

.ant-checkbox-inner::after {
    margin-left: 1px;
}

.ant-checkbox .ant-checkbox-inner {
    border: 1px solid #a1a0a0;
}



.ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-list-item-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-bottom: 38px;
    padding-left: 28px;
    line-height: 1.5714285714285714;
    flex: auto;
    transition: all 0.3s;
}


/* formlist styles */
.table-container table{
  text-align: left;
  overflow-x: auto;
  width: 100%;  
}
.table-container table th{
  text-align: left;
}
.table-container table td {
  text-align: left;
  min-width: 200px;
  padding-top:30px;
}
.table-container table tr{
    border-top: 1px solid #C9D6DF;
}
.add-node-button{
    float: right;
    margin-top: -45px;
    margin-right: 30px;
}
.formlist-container{
    border: 1px solid #C9D6DF;
    margin-top: 20px; 
    border-radius: 10px;
}
.formlist-title{
    padding-top: 20px;
    padding-left: 30px;
    margin-bottom:20px;
    color: #1E2022;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.formlist-remove{
    padding-top: 15px;
}

.formula-btn{
    float: right;
    margin-top: -43px;
    border-radius: 60px;
    color: #4079FC;
    width: 20px;
    position: relative;
    left: 35px;
}

.machine-checkbox{
    position: absolute;
    left: -20px;
}