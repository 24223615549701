.menucontainer {
  height: calc(90vh);
  overflow-y: auto;
  padding-bottom: 20px;
  font-family: 'Switzer-Regular' !important;
}

.menucontainer::-webkit-scrollbar {
  width: 1px;
}

.menucontainer::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #C9D6DF;
}

.menucontainer::-webkit-scrollbar-thumb {
  background-color: #C9D6DF;
}
.ant-menu-title-content {
  font-family: 'Switzer-semibold' !important;
  font-size: 14px !important;
  color: #1E2022;
}

.ant-menu-item-icon {
  width: 20px !important;
  height: 20px !important;
  margin-right: 10px !important;
}

.ant-layout-sider {
  max-width: 300px !important;
  width: 300px !important;
}

.logosection{
  height: 68px;
}
.menu-logo {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 0px;
}
.menu-logo .ant-menu-item-icon{
  width: 30px !important;
  height: 30px !important;
}
.menu-logo .ant-menu-item{
  padding-left : 20px !important;
}