.roaster-header{
    width:1200px;
    height:58px;
    border: none;
    background: #FFFFFF;
}

.roaster-header-text{
    font-size: 24px;
    color: #1E2022;
    font-style: normal;
    font-weight: 700px;
    line-height: normal;
    margin-top:-90px;
    margin-left:-20px;
}
.roaster-dates{
    width: 260px;
    height: 45px;
    border-radius: 5px;
}

.roaster-time-button{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95px;
    height: 32px;
    margin: auto;
    border: none;
    margin-left: 20px;
    padding-left: 33px;
    background-size: 14px;
    background-repeat: no-repeat;
    background-position: 15px center;
}

.button-style{
    border-top: 1px solid #C9D6DF; 
    height: 45px;
    margin-top:20px;
    /* width:1200px; */
    margin-left:-60px;
}

.button-next{
    width: 125px; 
    height: 50px;
    border-radius: 5px; 
    background: #4079FC;
    font-size:16px;
    float: right;
    font-weight: 600px;
    /* margin-top:20px;  */
    margin-right: 20px;
}

.button-back{
    width: 125px; 
    height: 50px;
    border-radius: 5px; 
    background: #FFFFFF;
    border: 1px solid #4079FC;
    margin-top:20px;  
    margin-left:70px;
    font-size:16px;
    font-weight: 600px;
}


/* Shift Edit */
.roaster-edit{
    width:1200px;
    height:58px;
    margin-left:-44px;
    border-bottom: 1px solid #C9D6DF;
    background: #FFFFFF;
}

.roaster-edit-text{
    font-size: 24px;
    color: #1E2022;
    font-style: normal;
    font-weight: 700px;
    line-height: normal;
    margin-top:-30px;
    margin-left:-20px;
}








