body {
  font-family: 'Switzer-Regular' !important;
}
body::-webkit-scrollbar {
  display: none !important;;
}
.bg-warning{
  background: #faad14 !important;
  color: white !important;
}

/* Approve Probation Request Evaluation */
.approval-table-container {
 display: flex;
}
.approval-table{
  border: 1px solid black;
  width: 100%;
  overflow: scroll;

}
.approval-table th{
  border: 1px solid black;
  padding: 4px;
}
.approval-table td{
  border: 1px solid black;
  width: 10%;
}
.approval-table select{
 
  padding: 4px;
}

.evaluation-container {
  border: 1px solid #e2e2e2;
  overflow-x: scroll;
  overflow-y: scroll;
}

.basemodel{
  max-height: 70vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 30px;
}

.basemodel::-webkit-scrollbar {
  width: 5px;
}

.basemodel::-webkit-scrollbar-thumb {
  background: #b6b6b6;
}

.maincontent{
  margin: 5px 1px !important;
  padding: 24px;
  background: #F3F6F9;
  overflow: scroll;
  height: 90vh;
}

.maincontent::-webkit-scrollbar {
  width: 10px;
 /* display: none; */
}

.maincontent::-webkit-scrollbar-thumb {
  background: #d6d6d6;
}

.ant-layout{
  background: #F3F6F9;
}