.chat-bot-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  width: 300px;
  transition: all 0.3s ease;
  z-index: 50;
  background-color: rgb(241, 247, 248);
  color: black;

}
.chat-table thead{
  /* overflow-y: scroll; */
 background-color: #ccc;
}

.chat-bot-container.open {
  height: 300px; /* Adjust height as needed */
}

.toggle-button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
}

.chat-window {
  height: 0;
  overflow-y: auto;
  transition: all 0.3s ease;
  z-index: 50;
  color: white;
}

.message {
  padding: 10px;
  background-color: #f0f0f0;
  margin-bottom: 5px;
}


.notification-container{
  position: fixed;
  bottom: 8%;
  right: 5%;
  z-index: 50;
  cursor: pointer;
  overflow: hidden;
}
.notification-icon{
  background-image: url('./icons/notification.gif'); /* Replace 'your-gif.gif' with the path to your GIF image */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 70px;
  width: 70px;
  border-radius: 70%;
  overflow: hidden;
  position: fixed;
  bottom: 15px;
  right: 5%;
  border: 1px solid #ccc;
  transition: all 0.3s ease;
  z-index: 50;
  cursor: pointer;
}
.notification-badge{
  position:fixed;
  bottom: 9%;
  right: 5%;
  cursor: pointer;
  border-radius: 80px;
}
